/**======================================================================
=========================================================================
Template Name: Datta Able Admin Template
Author: Codedthemes
supports: https://codedthemes.support-hub.io/
File: style.css
=========================================================================
=========================================================================
  == Table of Contents==
	- Generic classes
		-	Margin, Padding, Font class, text align, position, floating, overflow, background class, text color, display class, boarder class
	- Theme Elements
		-	Accordion, Button, tabs, typography, buttons, box shadow, Lable & Badges, Alert, Pagination, Breadcumb, Cards, Collapse,
        -   Carousel, Grid, Progress, Model, tooltip, popover, Datepicker, Gridstack, lightbox, notification, Nestable, pnotify, rating,
        -   Rangeslider, Slider, Syntax Highlighter, Tour, Treeview, Toolbar, Session Timeout, Session idle Timeout, offline, Animation
    - Forms
        -   Forms Elements, Advance Form Control, Validation, Masking, Wizard, Picker, Select
	- Pages
		-   Chat, authentication, Maintenance, Maps, Landingpage messages, task, Todo, Notes, Charts, Icons, Gallery, Editors,
        -   Invoice, Full Calender, File Upload,
=================================================================================
=================================================================================== */
// General theme contents
@import 'main';
@import 'variables';
@import 'general';
@import 'generic';
@import 'mixins/function';

// important Element
@import 'widget/widget';

// Theme Element
@import 'theme-elements/form';
@import 'theme-elements/radiobox-checkbox';
@import 'theme-elements/labels-badges';
@import 'theme-elements/data-tables';
@import 'theme-elements/authentication';
@import 'theme-elements/button';
@import 'theme-elements/alert';
@import 'theme-elements/tooltip';
@import 'theme-elements/popover';
@import 'theme-elements/nav';
@import 'theme-elements/modal';

// Other
@import 'other/chat';
@import 'other/prism';
@import 'other/toolbar';
@import 'other/switches';
@import 'other/wizard';
@import 'other/chart';
@import 'other/icon-lauouts';
@import 'other/calendar';
@import 'other/file-upload';

@import '~font-awesome/css/font-awesome.css';

@import "primeng/resources/themes/saga-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";

// Datepicker
@import '~@angular/cdk/overlay-prebuilt.css';
@import 'other/_datepicker.scss';

@import "../../node_modules/quill/dist/quill.core.css";
@import "../../node_modules/quill/dist/quill.snow.css";

@import "ckeditor5/dist/ckeditor5.css";

.ck-editor__editable_inline {
    max-height: 400px !important;
    min-height: 400px !important;
    overflow-y: auto;
}

.ck-source-editing-area {
    max-height: 400px !important;
    min-height: 400px !important;
}

.ck-rounded-corners 
.ck-source-editing-area textarea, 
.ck-source-editing-area textarea.ck-rounded-corners {
    max-height: 400px !important;
    min-height: 400px !important;
    overflow-y: auto !important;
}

// Status
.circle-status {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    display: block;
    float: right;
    margin-right: 30px;
}

// Status Cores
.em-desenvolvimento {
    background-color: green;
}
.aguardando-aprovacao {
    background-color: #007bff;
}
.nao-aprovado {
    background-color: yellow;
}
.a-homologar {
    background-color: indigo;
}
.ativo {
    background-color: teal;
}
.cancelado {
    background-color: red;
}
.finalizado {
    background-color: #007bff;
}

// Validations

.ng-invalid {
    &.ng-touched {
        border-color: #dc3545 !important;
    }
}

// Posição do botão Visualizar Gráfico
.visualizar-grafico {
    margin-bottom: 20px;
}

.swal2-container {
    z-index: 10000 !important;
}

.dp-picker-input {
    background: #F9FCFA;
    border: 1px solid #CED4DA;
    border-radius: 4px;
    display: block;
    position: relative;
    height: calc(2rem + 1px) !important;
    max-width: 300px;
    font-size: 1rem !important;
    padding-left: 16px;
    padding-right: 16px;
    cursor: pointer;
}

.bold {
    font-weight: 700;
}

.p-multiselect-filter-container {
    input {
        height: calc(2rem + 1px);
    }
}

.p-multiselect {
    color: #495057;
    width: 100%;
    align-items: center;
}

.p-multiselect-label {
    display: flex !important;
    flex-wrap: wrap !important;
}

.p-multiselect-token {
    margin: 8px 3px;
}

.jqx-tree-grid-checkbox {
    background-color: #DEE2E6 !important;
    border-radius: 4px !important;
    border: none !important;
}

.jqx-checkbox-check-checked {
    background-color: #007BFF !important;
    border-radius: 4px !important;
}

.jqx-grid-cell-hover {
    background-color: #e1e1e17c !important;
}

.jqx-grid-column-header {
    background-color: #E8E8E8 !important;
    font-size: 13px !important;
}

.dp-picker-input:disabled {
    cursor: default;
}

.p-dropdown.p-disabled {
    background: #e9ecef !important;
    opacity: 1;
}

.p-dropdown-trigger-icon {
    font-size: 10px;
}

p-dropdown.ng-dirty.ng-invalid > .p-dropdown { 
    border-color: #ced4da !important;
}

p-dropdown.ng-touched.ng-invalid > .p-dropdown { 
    border-color: #dc3545 !important;
}

